<template>
    <div class="driv-part-finder-specification-tab">
        <form
            class="driv-part-common search-container with-application-type"
            @submit.prevent.stop="handleSearch"
        >
            <fmmp-autocomplete
                :class="`driv-part-common ${initialField.name}`"
                :config="autocompleteConfig"
                :id="initialField.name"
                :name="initialField.name"
                :items="initialField.items"
                v-model="initialField.model"
                :placeholder="getTranslation(initialField.placeholder)"
                :loading="initialField.loading"
                @input="initialField.onInput"
            ></fmmp-autocomplete>
            <div v-if="initialField.model" class="specification-search-fields-wrapper">
                <div class="specification-dropdowns">
                    <fmmp-autocomplete
                        v-for="field in options"
                        :key="field"
                        :class="`driv-part-common ${field}`"
                        :config="autocompleteConfig"
                        :id="field"
                        :name="field"
                        :items="fieldsConfig[field].items"
                        :disabled="fieldsConfig[field].disabled"
                        v-model="fieldsConfig[field].model"
                        :placeholder="getTranslation(fieldsConfig[field].placeholder)"
                        :loading="fieldsConfig[field].loading"
                        @input="(value) => handleFieldInput(field, value)"
                        :withImage="field === FIELDS.BRAND"
                    ></fmmp-autocomplete>
                    <fmmp-autocomplete
                        v-if="isDescriptionShown"
                        :key="descField"
                        :class="`driv-part-common ${descField}`"
                        :config="autocompleteConfig"
                        :id="descField"
                        :name="descField"
                        :items="fieldsConfig[descField].items"
                        :disabled="fieldsConfig[descField].disabled"
                        v-model="fieldsConfig[descField].model"
                        :placeholder="getTranslation(fieldsConfig[descField].placeholder)"
                        :loading="fieldsConfig[descField].loading"
                        @input="(value) => handleFieldInput(descField, value)"
                    ></fmmp-autocomplete>
                </div>
                <button
                    type="submit"
                    class="button-main search"
                    v-if="searchButton"
                    :disabled="searchButton.isDisabled"
                >
                    <fmmp-i18n text="look it up" />
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import configs from "../../../common/specification-search/SpecificationSearchConfig";
import {
    getBrandsOptionsFromString,
    getModelFromFieldObject,
    getTorqueContentSilosFromString,
    resetField,
} from "../../../common/specification-search/helpers";
import {
    FIELDS,
    EMPTY_ITEM,
    PART_DESCRIPTION_ATTRIBUTE_NAME,
} from "../../../common/specification-search/constants";
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    SPECIFICATION_PARTS,
} from "../../../common/partFinderCorporate.constants";
import { getOptionsConfigBySelectedApplicationTypes } from "../../../common/ymm-search/helpers";
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../common/partFinderCorporate.helpers";
import { SPECIFICATION_PROPERTIES } from "../../../driv-part-finder-part-results-page/clientlibs/src/constants";
import { checkForTorque } from "../../../driv-part-results-refine-search/clientlibs/src/helpers";

const { fieldsConfig, fieldsOptionsFromAEM, optionsConfig, searchButton, initialField } = configs;

export default Vue.component("tab-specification", {
    props: {
        specificationTypes: {
            type: String,
            default: "",
        },
        specificationBrandType: {
            type: String,
            default: "",
        },
        searchResultsPath: {
            type: String,
            default: "",
        },
        torqueContentSilos: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            optionsConfig,
            fieldsConfig,
            searchButton,
            initialField,
            FIELDS,
            descField: FIELDS.PART_DESCRIPTION,
        };
    },
    computed: {
        config() {
            return getOptionsConfigBySelectedApplicationTypes({
                optionsConfig: this.optionsConfig,
                type: this.initialField.model,
            });
        },
        options() {
            return Object.keys(this.config.fields).filter(
                (option) => option !== FIELDS.PART_DESCRIPTION && fieldsConfig[option],
            );
        },
        isDescriptionShown() {
            return (
                this.initialField.model.value === SPECIFICATION_PARTS &&
                this.fieldsConfig[FIELDS.PART_TYPE].items.length &&
                this.fieldsConfig[FIELDS.PART_DESCRIPTION].items.length &&
                this.fieldsConfig[FIELDS.PART_DESCRIPTION].items[0]?.label !== EMPTY_ITEM.label
            );
        },
        isTorqueSearch() {
            return checkForTorque(initialField);
        },
        currentSearchType() {
            if (this.isTorqueSearch) {
                return PART_FINDER_CORPORATE_SEARCH_TYPES.TORQUE;
            }
            return PART_FINDER_CORPORATE_SEARCH_TYPES.SPECIFICATION;
        },
    },
    watch: {
        "initialField.items": function (newItems) {
            if (newItems.length === 1) {
                this.initialField.model = newItems[0];
                this.initialField.onInput(newItems[0]);
            }
        },
        isTorqueSearch: function (isTorque) {
            if (isTorque) {
                if (!JSON.parse(this.torqueContentSilos).length) {
                    this.searchButton.isDisabled = true;
                }
            }
        },
    },
    methods: {
        getTranslation,
        handleFieldInput(field, value) {
            this.config.fields[field].onInput(value);
        },
        handleSearch() {
            if (this.searchResultsPath) {
                const specificationType = getModelFromFieldObject(this.initialField);
                const brands = getModelFromFieldObject(this.fieldsConfig[FIELDS.BRAND]);
                const partTypeId = getModelFromFieldObject(this.fieldsConfig[FIELDS.PART_TYPE]);
                const partDescription = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.PART_DESCRIPTION],
                );
                const contentSilos = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.TORQUE_CONTENT_SILOS],
                );

                const queryObj = {
                    searchType: this.currentSearchType,
                    specificationType,
                    partTypeId,
                    brands,
                    contentSilos,
                };

                const hashString = partDescription?.value
                    ? {
                          [SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS]: [
                              {
                                  name: PART_DESCRIPTION_ATTRIBUTE_NAME,
                                  value: partDescription?.value,
                              },
                          ],
                      }
                    : "";

                const url = createUrlToGoToSearchResults({
                    queryObj,
                    path: this.searchResultsPath,
                    hashString,
                });

                const eventParams = Object.values(queryObj).reduce((acc, item) => {
                    if (!this.isTorqueSearch) {
                        if (item.label) {
                            acc = [...acc, item.label];
                        }
                        return [...acc];
                    }
                    return [specificationType.label, contentSilos.label];
                }, []);

                if (!this.isTorqueSearch && hashString) {
                    eventParams.push(hashString.attribute_filters[0].value);
                }

                Vue.Global.Analytics.trackEvent(
                    "part-search",
                    "search-part-filter",
                    eventParams.join("|"),
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }

            return false;
        },
    },
    created() {
        if (this.specificationTypes) {
            initialField.getItems(this.specificationTypes);

            if (this.specificationBrandType) {
                fieldsOptionsFromAEM[FIELDS.BRAND] = getBrandsOptionsFromString(
                    this.specificationBrandType,
                );
            }
            if (this.torqueContentSilos) {
                fieldsOptionsFromAEM[FIELDS.TORQUE_CONTENT_SILOS] = getTorqueContentSilosFromString(
                    this.torqueContentSilos,
                );
            }
        }
    },
    beforeDestroy() {
        resetField(initialField);
    },
});
</script>
